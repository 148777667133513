import { CardContainer } from "clutch/src/Card/Card.js";

import {
  cssDamage,
  cssDamageContainer,
  cssInGameContainer,
  cssLoading,
  cssPlayerList,
  cssTeam,
} from "@/game-lol/components/in-game-template.style.mjs";
import aramBenchTemplate from "@/game-lol/components/in-game-template-aram.mjs";
import damageTemplate from "@/game-lol/components/in-game-template-damage.mjs";
import iconsTemplate from "@/game-lol/components/in-game-template-icons.mjs";
import settings from "@/game-lol/components/in-game-template-settings.mjs";
import summonerTemplate from "@/game-lol/components/in-game-template-summoner.mjs";
import tabsTemplate from "@/game-lol/components/in-game-template-tabs.mjs";
import html from "@/util/html-template.mjs";

const template = html`
  ${iconsTemplate} {{#settings}} ${settings} {{/settings}}

  <div
    class="sidebar-align ${cssInGameContainer()}"
    data-flow="{{flow}}"
    data-phase="{{currPhase}}"
    data-queue-id="{{queueId}}"
  >
    {{#loading}}
    <div class="${cssLoading()} loading">{{{icon}}}</div>
    {{/loading}} {{#aramBench}} ${aramBenchTemplate} {{/aramBench}} {{#tabs}}
    ${tabsTemplate} {{/tabs}} {{#summoners}}
    <div class="${cssTeam()} upper-team teammates">
      <span class="type-caption--bold team-title">{{teammatesTitle}}</span>
      {{#teammates}}
      <div class="${cssPlayerList()}" style="{{style}}">
        {{#players}} ${summonerTemplate} {{/players}}
      </div>
      {{/teammates}}
    </div>
    <div class="flex gap-sp-2 damage-breakdown">
      <div class="flex-grow ${cssDamageContainer()}">
        <div class="${CardContainer()}">
          <div class="${cssDamage()} card-frame card-contents">
            {{#teammatesDamage}} ${damageTemplate} {{/teammatesDamage}}
          </div>
        </div>
        <div class="${CardContainer()} opponent-damage">
          <div class="${cssDamage()} card-frame card-contents">
            {{#opponentsDamage}} ${damageTemplate} {{/opponentsDamage}}
          </div>
        </div>
      </div>
      {{#videoAd}}
      <div>{{element}}</div>
      {{/videoAd}}
    </div>
    <div class="${cssTeam()} lower-team opponents">
      <span class="type-caption--bold team-title">{{opponentsTitle}}</span>
      {{#opponents}}
      <div class="${cssPlayerList()}" style="{{style}}">
        {{#players}} ${summonerTemplate} {{/players}}
      </div>
      {{/opponents}}
    </div>

    {{/summoners}}
  </div>
`;

export default template;
