import { cssButton, cssButtonGrp } from "clutch/src/Button/Button.jsx";
import { CardContainer } from "clutch/src/Card/Card.js";
import { cssSelect } from "clutch/src/Select/Select.jsx";

import buildsTemplate from "@/game-lol/components/in-game-template-builds.mjs";
import arenaBuildTemplate from "@/game-lol/components/in-game-template-builds-arena.mjs";
import suggestionsTemplate from "@/game-lol/components/in-game-template-suggestions.mjs";
import arenaSuggestionsTemplate from "@/game-lol/components/in-game-template-suggestions-arena.mjs";
import {
  cssContainer,
  cssHeader,
} from "@/game-lol/components/in-game-template-tabs.style.mjs";
import html from "@/util/html-template.mjs";

const template = html`
  <section class="${CardContainer()} tabs">
    <div
      id="build-tabs"
      class="${cssContainer()} card-frame card-contents"
      data-active-tab="{{activeTab}}"
    >
      <header class="${cssHeader()}">
        <div class="flex align-center gap-sp-2">
          <nav class="tab-list">
            {{#tabslist}}
            <button
              class="tabs-tab type-form--tab"
              data-active="{{isActive}}"
              data-disabled="{{disabled}}"
              data-testid="{{id}}"
              onclick="{{onclick}}"
            >
              <div class="contents">
                <span>{{{icon}}}</span>
                <span>{{label}}</span>
              </div>
            </button>
            {{/tabslist}}
          </nav>
          <span class="timer type-caption color-shade2">{{timer}}</span>
        </div>

        <div class="flex align-center gap-sp-2">
          {{#localChampion}}
          <img src="{{src}}" class="champ-img round" width="24" height="24" />
          {{/localChampion}} {{#roleOptions}}
          <div class="${cssButtonGrp()} button-group">
            {{#roles}}
            <button
              onclick="{{onClick}}"
              class="${cssButton()} icon-only"
              data-tip="{{tooltip}}"
              data-active="{{isSelected}}"
            >
              <span>{{{icon}}}</span>
            </button>
            {{/roles}}
          </div>
          {{/roleOptions}} {{#opponentSelect}}
          <div
            class="${cssSelect()} opponent-select"
            data-disabled="{{disabled}}"
            data-show-options="{{isOpen}}"
          >
            <button onclick="{{toggleSelectOpen}}" class="selectButton">
              <div class="image">
                <img src="{{selectedOpponent}}" width="20" height="20" />
              </div>
              <span class="type-form--button text">{{text}}</span>
              <svg viewBox="0 0 24 24" class="caret">
                <use href="#caret-down" />
              </svg>
            </button>
            <ul class="{{optionsClass}}">
              {{#opponentOptions}}
              <li onclick="{{onClick}}" class="{{champClass}}">
                <div class="option--left">
                  <div class="option--image"><img src="{{image}}" /></div>
                  <span class="option--text type-form--button">{{text}}</span>
                </div>
                <div class="{{checkClass}}">
                  <svg viewBox="0 0 24 24">
                    <use href="#checkmark" />
                  </svg>
                </div>
              </li>
              {{/opponentOptions}}
            </ul>
            <div class="select-close-trigger" onclick="{{close}}"></div>
          </div>
          {{/opponentSelect}}
          <div class="${cssButtonGrp()} button-group">
            {{#settingsButtons}}
            <button
              onclick="{{onclick}}"
              class="${cssButton()} icon-only"
              data-emphasis="{{emphasis}}"
              data-tip="{{tooltip}}"
              data-place="{{place}}"
            >
              <span>{{{icon}}}</span>
            </button>
            {{/settingsButtons}}
          </div>
        </div>
      </header>
      {{#lowRoleWarning}}
      <div class="low-role-warning">
        <div class="side">
          <span>{{{icon}}}</span>
          <span class="type-subtitle--bold">{{text}}</span>
        </div>
        {{#suggested}}
        <div class="side">
          <span class="type-subtitle--bold">{{{label}}}:</span>
          <button onclick="{{onClick}}">
            <span>{{{icon}}}</span>
            <span class="type-subtitle--bold">{{{text}}}</span>
          </button>
        </div>
        {{/suggested}}
      </div>
      {{/lowRoleWarning}} {{#suggestions}} ${suggestionsTemplate}
      {{/suggestions}} {{#builds}} ${buildsTemplate} {{/builds}}
      {{#arenaSuggestions}} ${arenaSuggestionsTemplate} {{/arenaSuggestions}}
      {{#arenaBuild}} ${arenaBuildTemplate} {{/arenaBuild}}
    </div>
  </section>
`;

export default template;
